// Only code specific to this site should be here. Anything that can be used across multiple sites
// should be pushed into the KMS.
import "lazysizes";
import { ProportionalImageNormalisation } from "@keodesign/proportional-image-normalisation";
import {FloatLabels} from "@keodesign/float-labels";

var html_classes = document.documentElement.classList;

html_classes.remove("no-js");
html_classes.add("js");

window.onload = function() {    

	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
	new FloatLabels(".js-float-wrap");
	ProportionalImageNormalisation(".scale-me", 75, 0.525);

	change_file_browse_button("browse files");
	
};

function change_file_browse_button(content){
	var browse_buttons = document.querySelectorAll(".file-browse");
	if(browse_buttons){
		for(var i=0; i<browse_buttons.length; i++){
			browse_buttons[i].innerHTML = content;
		}
	}
}

// add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});
